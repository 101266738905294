#profile-wrapper { 
  display: flex; align-items: center; justify-content: center;
  h4 {
    color: #fed517; margin-bottom: 0px;
  }
  .btn-main.submit {
    width: 100%; 
  }
  .fiches-box {
    .btn-main {
      margin-bottom: 10px;
    }
  }
  .column.with-background {
    float: left; width: 100%;
  }
  .error-message {
    color: #fed517; font-weight: 500; font-style: italic; 
    margin-top: 5px; font-size: 14px; margin-bottom: 0;
  }
}

