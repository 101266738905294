@import './reset.scss';
.home-werken {
	overflow: hidden;
}
.langmenu {
	position: fixed; top: 10px; right: 20px;
	font-size: 13px; z-index: 5000;
	.lang {
		margin-left: 10px; cursor: pointer;
		&.active {
			color: #FED417; font-weight: 600;
		}
	}
}
.langmenu-mobile {
	float: right; font-size: 13px; display: none;
	.lang {
		margin-left: 10px; cursor: pointer;
		&.active {
			color: #FED417; font-weight: 600;
		}
	}
}
section.headline {
	padding-top: 150px; position: relative; padding-bottom: 0;
	.content {
		padding: 0 20px; display: flex;
    	align-items: center;
	}
	.half-text {
		float: left; width: 50%;
		padding: 30px 0;  padding-right: 30px;
		.intro-text {
			float: left; width: 100%;
		}
	}
	.btn-main {
		margin-top: 15px;
	}
	.half-image {
		width: 50%; float: left; padding-top: 25%; 
		right: 0; background-size: cover; background-repeat: no-repeat;
		border-radius: 9px; transform: translateY(30px);
		background-position: center;
	}
}
.popup {
	position: fixed; top: 0px; left: 0px; right: 0px; bottom: 0px;
	display: flex; z-index: 5000000; justify-content: center;
	align-items: center;
	.popup-background {
		position: absolute; top: 0px; left: 0px; right: 0px; 
		bottom: 0px; background-color: #0c0f1af7; 
	}
	.popup-inner {
		max-width: 600px; padding: 40px; background: #181D29; border-radius: 13px;
		position: relative; z-index: 5000;
		ul {
			float: left; width: 100%; margin-bottom: 20px;
			li {
				float: left; margin-right: 10px; padding: 3px 10px;
    			border-radius: 3px; background: #ffffff26; font-weight: 400;
    			font-size: 12px; font-weight: 600; cursor: pointer;
    			&.active {
    				background: #FED417; color: #000;
    				&:hover {
    					 color: #000;
    				}
    			}
    			&:hover {
    				color: #FED417;
    			}
			}
		}
	}
}
.cookies-popup {
	display: flex; flex-direction: column; align-content: center; align-items: center;
	text-align: center; position: relative;
	h3 {
		font-size: 20px;
	}
	img {
		position: absolute; left: -20px; top: -20px;
	}
	.cookies-buttons {
		.btn-main {
			margin: 10px 5px 0 5px; border-radius: 0; font-size: 19px; 
			padding: 0 15px;
			&.decline {
				background: #FFF; color: #333;
				border-color: #FFF; font-weight: 400;
			}
		}
	}

}

.niches-descr {
	float: left; width: 100%;
}

.section-werken section.headline .buttons {
	display: flex; justify-content: center; margin-top: 0px !important;
	.btn-main.btn-line {
		background: #FFF; margin-left: 10px; color: #0B2F50;
	}
}
.intro {
	width: 100%; height: 100vh; display: flex;
	justify-content: center; align-items: center;
	.intro-wrapper {
		display: flex; flex-direction: column;
		.logo {
			display: flex; flex-direction: column; align-items: center;
			.logo-1 {
				max-width: 100%; width: 80px; height: auto; margin-bottom: 40px;
				opacity: 0;
				-webkit-animation: fadein 1s ease-in normal 0s forwards;
			    -moz-animation: fadein 1s ease-in normal 0s forwards;
			    animation: fadein 1s ease-in normal 0s forwards;
			}
			.logo-2 {
				max-width: 100%; width: 728px; height: auto; margin-bottom: 20px;
				opacity: 0;
				-webkit-animation: fadein 1s ease-in normal 0.5s forwards;
			    -moz-animation: fadein 1s ease-in normal 0.5s forwards;
			    animation: fadein 1s ease-in normal 0.5s forwards;
			}
			.logo-3 {
				max-width: 100%; width: 280px; height: auto; opacity: 0;
				-webkit-animation: fadein 1s ease-in normal 1s forwards;
			    -moz-animation: fadein 1s ease-in normal 1s forwards;
			    animation: fadein 1s ease-in normal 1s forwards;
			}
		}
	}
	.menu-items {
		display: flex; padding-top: 50px; 
		grid-gap: 10px; justify-content: center;
		a, .btn-main {
			width: 33%; border-radius: 0; font-size: 19px; padding: 0; font-weight: 400;
		}
		a:nth-child(1), .btn-main:nth-child(1) {
			opacity: 0;
			-webkit-animation: fadein 1s ease-in normal 1.5s forwards;
			-moz-animation: fadein 1s ease-in normal 1.5s forwards;
			animation: fadein 1s ease-in normal 1.5s forwards;
		}
		a:nth-child(2), .btn-main:nth-child(2) {
			opacity: 0;
			-webkit-animation: fadein 1s ease-in normal 2.5s forwards;
			-moz-animation: fadein 1s ease-in normal 2.5s forwards;
			animation: fadein 1s ease-in normal 2.5s forwards;
		}
		a:nth-child(3), .btn-main:nth-child(3) {
			opacity: 0;
			-webkit-animation: fadein 1s ease-in normal 3.5s forwards;
			-moz-animation: fadein 1s ease-in normal 3.5s forwards;
			animation: fadein 1s ease-in normal 3.5s forwards;
		}
	}
}

@keyframes fadein {
    from { opacity: 0; } to { opacity: 1; }
}
.swiper-slide {
	h3 {
		color: #FED417;
	}
}
section {
	padding: 80px 0; float: left; width: 100%;
	&.center {
		text-align: center;
	}
	&.less-margin {
		padding-bottom: 0px;
	}
	&.with-background {
		background-color: #ffffff0d; text-align: center; 
		float: left; width: 100%; display: flex; 
		flex-direction: column; align-items: center;
		padding: 40px 0;
		h3, h2 {
			margin-bottom: 0px;
		}
		.description {
			margin-bottom: 15px;
		}
	}
}
.home {
	float: left; margin-top: 100px;
}
.page-wrapper {
	margin: auto; padding-top: 140px;
	.content {
		float: left; width: 100%;
	}
}
.section-werken section.headline .contact-form .btn-main {
	background-color: #FED417; color: #000000; border-color: #FED417;
}
.carousel {
	width: 100%; height: 70vh; background: #333; display: grid;
    align-items: center; background-size: cover; background-repeat: no-repeat;
    background-position: center; position: relative;
    &::before {
    	content: ''; position: absolute; top: 0px; left: 0px; right: 0px; bottom: 0px;
    	background: #00000061;
    }
    h1 {
    	background-color: transparent; padding: 0px; color: #FFF;
    	// span {
    	// 	color: #08182E; background-color: #FED417;
  		// 	padding: 0 15px; margin-right: 10px;
    	// }
    }
    .intro-text {
    	float: left; width: 100%; margin-bottom: 20px;
    }
    .btn-main {
    	margin-right: 10px;
    }
    .btn-main, .btn-main-line {
    	margin-top: 10px;
    }
}
.column-2n {
	display: grid; grid-template-columns: repeat(2, 1fr);
	grid-gap: 40px; text-align: left; float: left;
	align-items: center;
	&.flip {
		flex-direction: column-reverse;
	}
	&.with-background {
		padding: 40px; background-color: #ffffff0d;
		border-radius: 9px;
	}
}
.column {
	&.with-background {
		padding: 40px; background-color: #ffffff0d;
		border-radius: 9px;
	}
}
.column.newspaper {
	column-count: 2; column-gap: 40px;
}
.column.half {
	width: 50%; float: left; padding-right: 40px;
}
.column-background {
	width: 100%; padding-top: 100%; background-size: cover; 
	background-repeat: no-repeat; background-position: center; 
	border-radius: 9px; background-color: #333;
}
.column-box {
	background-color: #ffffff0d; padding: 40px;
	width: 100%; display: flex; border-radius: 9px;;
	flex-direction: column; height: 100%;
    justify-content: center; position: relative;
    h3 {
		margin-bottom: 0px;
	}
}
.column-form {
	background-color: #08182E; padding: 40px;
	border: 1px solid rgba(255, 255, 255, 0.2);
	border-radius: 9px;

}
.column-map {
	width: 100%; padding-top: 100%; position: relative;
	border-radius: 9px; overflow: hidden;
	.map {
		position: absolute; left: 0px; top: 0px; right: 0px;
		bottom: 0px; border: none; width: 100%; height: 100%;
	}
}

.niches-menu {
	display: flex; width: 100%; margin-bottom: 20px; 
	justify-content: center; grid-gap: 15px;
	.btn-niche {
	  font-family: "Geogrotesque"; font-style: normal; font-weight: 600; 
	  font-size: 14px; text-align: center;  float: left;
	  text-decoration: none;  padding: 9px 20px; border-radius: 4px; 
	  overflow: hidden; transition: 0.25s ease background; position: relative;
	  border: 2px solid #142236; color: #FFFFFF; background-color: #142236;
	  padding-left: 35px; cursor: pointer;
	  &.active, &:hover {
	  	border: 2px solid #FED417; color: #000000; background-color: #FED417;
	  }
	  &.btn-construction {
	  	background-image: url('../images/ico-construction.svg');
	  	background-position: left 10px center;
	  	background-repeat: no-repeat;
	  	&.active, &:hover  {
	  		background-image: url('../images/ico-construction-active.svg');
	  	}
	  }
	  &.btn-engineering {
	  	background-image: url('../images/ico-engineering.svg');
	  	background-position: left 10px center;
	  	background-repeat: no-repeat; background-size: 18px auto;
	  	&.active, &:hover  {
	  		background-image: url('../images/ico-engineering-active.svg');
	  	}
	  }
	  &.btn-finance {
	  	background-image: url('../images/ico-finance.svg');
	  	background-position: left 10px center;
	  	background-repeat: no-repeat;
	  	&.active, &:hover  {
	  		background-image: url('../images/ico-finance-active.svg');
	  	}
	  }
	}
}

.blog-section {
	h2 {
		float: left;
	}
	.btn-main-line {
		float: right;
	}
	.blog-items {
		display: grid; grid-template-columns: repeat(3, 1fr);
		grid-gap: 20px; overflow: hidden; width: 100%;
		* {
			transition: 0.25s ease all; 
		}
		.btn-blog {
			.blog-image {
				position: relative; width: 100%; padding-top: 50%; 
				border-radius: 9px 9px 0 0; overflow: hidden;
				.image {
					background-size: cover; background-repeat: no-repeat; 
					background-position: center; position: absolute;
					left: 0px; right: 0px; top: 0px; bottom: 0px;
				}
			}
			.blog-item {
				background-color: #ffffff0d; border-radius: 9px;
				padding: 20px; float: left;
				.date {
					float: right; font-size: 12px; color: #6c757d;
				}
				h4 {
					width: 100%; float: left; margin-top: 16px;
				}
				.author-block {
					float: left; width: 100%; margin-top: 15px; display: flex;
					align-items: center;
					.author-img {
						width: 30px; height: 30px; background-size: cover; 
						background-position: center; background-repeat: no-repeat;
						border-radius: 100%; margin-right: 15px;
					}
					.author {
						font-size: 13px;
					}
				}
			}
			&:hover {
				.blog-image .image {
					transform: scale(1.1);
				}
				h4 {
					color: #FED417;
				}
			}
		}
	}
}
.home-werken {
	section.headline .half-text {
		display: flex; flex-direction: column; text-align: center;
		h1 {
			transform: rotate(-2deg);
		}
		h1 span {
			font-weight: 400; margin-bottom: 5px;
			&:nth-child(2) {
				font-weight: 800; color: #FED417;
			}
		}
	}
}
.section-werken.page-home {
	header a,
	header .menu-sections .btn-main {
		color: #FED417;
	}
}


.swiper-wrapper h4 {
	color: #FED417;
}
.jobs-filters-wrapper {
	float: left; width: 100%; padding-bottom: 15px; border-bottom: 1px solid #ffffff30;
	.jobs-filters {
		display: flex; 
		flex-direction: row; justify-content: flex-start; height: 100%;
		.filter {
			float: left; margin-right: 20px;
			ul {
				float: left; display: flex; grid-gap: 5px; font-size: 13px; 
				li, a {
					font-size: 10px; white-space: nowrap; cursor: pointer;
					text-decoration: none;
					&.active {
						color: #FED417;
					}
					&.transparent {
						margin-top: 0px; padding: 0 5px; background-color: rgba(148, 141, 141, 0.25); width: auto;
						&:hover {
							color: #FED417;
						}
					}
				}
			}
			
		}
		.clear-filter {
			font-size: 12px; float: right; text-align: right; text-decoration: underline; cursor: pointer; font-style: normal;
		}
	}
}

.pager {
	margin-left: auto; float: right; margin-top: 25px; 
	display: flex; grid-gap: 7px;
	li {
		width: 28px; text-align: center; background: rgb(20, 28, 48); 
		border-radius: 9px; border: 1px solid rgba(255, 255, 255, 0.2); 
		font-size: 14px; cursor: pointer; padding: 2px 0;
		&.active {
			background: #fed417; color: #000; font-weight: 600;
		}
	}
}

.job-section {
	display: flex; grid-gap: 20px; align-items: flex-start;
	.job-jobs {
		display: grid; width: 100%; grid-template-columns: repeat(2, 1fr);
		grid-gap: 10px; align-items: start;
		.job {
			padding: 20px; background: rgb(20 28 48); border-radius: 9px;
			border: 1px solid #fff3; display: flex; flex-direction: column;
    		justify-content: flex-start; height: 100%;
    		.top {
				margin-top: 10px; display: flex; align-items: center; float: left;
				.tag {
					font-size: 12px;
					&.transparent {
						margin-top: 0px; padding: 0 5px;
						background-color: #948d8d36; width: auto;

					}
				}
    		}
			.location {
				display: flex; grid-gap: 10px; align-items: center;
			}
			h4 {
				float: left; width: 100%; margin: 10px 0; color: #FED417;
			}
			.subline {
				width: 100%; margin-top: 15px; display: flex;
				grid-gap: 15px; float: left; font-size: 11px;
				color: #c0cbd5; margin-top: auto; align-items: center;
				padding-top: 10px;
				.date {}
				.location {}
				.btn-watch {
					margin-left: auto; color: #FED417; font-weight: 600;
					text-decoration: none;
				}
			}
			&.advertising {
				grid-column: 1/-1;
				h2 { color: #FED417; margin-bottom: 5px; font-size: 28px; }
				h3 { font-weight: 300; }
			}
		}
	}


	.job-filters {
		display: flex; flex: 0 0 280px; grid-gap: 10px; width: 100%;
		flex-direction: column;
		h4 {
			margin-bottom: 0px; color: #FFF; background: rgba(148, 141, 141, 0.2117647059);
    		display: inline-table; align-self: flex-start;
		}
		.filter {
			width: 100%; background: rgb(0 0 0 / 15%); border-radius: 9px;
			border: 1px solid #fff3; padding: 20px;
			.tickbox-wrapper {
				line-height: 15px; padding: 5px 0; float: left; width: 100%;
			}
		}
		.profile {
			h4 {
				background: transparent; display: initial; align-self: center;
			}
		}

		.jobalert-filter {
			padding-left: 60px; background: url('../images/ico-jobalert.svg') left center no-repeat;
			padding-top: 3px;
			h4 {
				margin-bottom: 0px;
			}
		}
		.description {
			float: left; width: 100%;
			margin-bottom: 15px;
		}
		.list-niches {
			flex-direction: column;
			div {
				display: flex; align-items: center;
			}
			li {
				float: left; width: auto; cursor: pointer;
				// &.active {
				// 	color: #FED417;
				// }
			}
			i {
				margin-left: 5px; font-size: 12px; font-style: normal;
				white-space: nowrap;
			}
		}
		.list-filters {
			li {
				font-family: "Geogrotesque"; font-style: normal; font-weight: 600; 
				font-size: 12px; text-align: center; color: #FFF; float: left;
				text-decoration: none; float: left; margin-bottom: 5px;
				padding: 5px 15px; border-radius: 4px; overflow: hidden;
				transition: 0.25s ease all; position: relative; text-align: left;
				border: 1px solid #ffffff73; cursor: pointer; margin-right: 5px;
				&:hover, &.active {
					background-color: #FFF; color: #000;
				}
			}
		}
		.btn-main {
			width: 100%;
		}
	}
}

.team-section {
	display: grid; width: 100%; grid-template-columns: repeat(4, 1fr);
	grid-gap: 10px;
}

.profile {
	background-color: #ffffff0d; border-radius: 9px;
	padding: 20px; float: left; text-align: center;
	display: flex; flex-direction: column;
  	align-items: center;
  * {
		transition: 0.25s ease all; 
	}
  h4 {
  	margin-bottom: 3px;
  }
  .job {
  	color: #6c757d; font-size: 14px;
  }
	.avatar-wrapper {
		max-width: 150px; width: 100%; margin-bottom: 15px;
	}
	.avatar {
		width: 100%; padding-top: 100%; border-radius: 100%;
		background-color: #FFF; background-size: cover;
		background-repeat: no-repeat; background-position: center;
	}
}

.socials {
	display: flex; grid-gap: 5px; margin-top: 10px;
	.btn-social {
		width: 35px; height: 35px; border-radius: 9px;
		border: 1px solid #fff3; color: #FFF;
		display: flex; align-items: center; justify-content: center;
		&:hover {
			background-color: #fed417;
		}
		// &.btn-linkedin:hover {
		// 	background-color: #0077b5;
		// }
		// &.btn-mail:hover {
		// 	background-color: #1da1f2;
		// }
	}
}
#vestigingen {
	align-self: flex-start; padding-top: 30px;
}
.contact {
	.contact-item {
		float: left; width: 100%; margin-bottom: 30px;
		padding-left: 90px;
		background: url('../images/contact-2-3.svg') left top no-repeat;
		&.second {
			background: url('../images/contact-2.svg') left top no-repeat;
		}
	}
	h3 {
		float: left; width: 100%;
	}
	.contact-line {
		float: left; width: 100%; margin-bottom: 10px; display: flex;
		align-items: center;
		strong {
			margin-right: 15px;
		}
		&.top {
			align-items: flex-start;
		}
		.icon {
			margin-right: 10px; display: flex;
			align-items: center;
		}
	}
	.socials {
		float: left; width: 100%;
	}
}
.section-werken {
	.contact {
		.contact-item {
			background: url('../images/contact-1-3.svg') left top no-repeat;
			padding-left: 90px;
			&.second {
				background: url('../images/contact-2-2.svg') left top no-repeat;
			}
		}
	}
}

.jobalert {
	padding: 40px; background-color: #ffffff0d;
	border-radius: 9px;
	.form-newsletter {
		max-width: 450px; margin: auto; position: relative;
		.btn-main {
			position: absolute; right: 0px; top: 0px;
			border-radius: 0 9px 9px 0; height: 35px;
			height: 51px; display: flex; align-items: center;
		}
		input[type=text] {
			padding: 15px; padding-left: 50px;
		}
		svg {
			position: absolute; top: 14px; left: 20px;
		}
	}
}

.blog-details {
	float: left; width: 100%; margin-top: 80px;
	h2 {
		width: 75%;
	}
	.blog-image {
		float: left; width: 100%; padding-top: 25%;
		margin-top: 20px; background-size: cover; margin-bottom: 40px;
		background-repeat: no-repeat; background-position: center;

	}
	.intro-text {
		width: 100%;
	}
	.author {
		display: flex; align-items: center; float: right;
		margin-top: -20px;
		.author-image {
			width: 40px; height: 40px; border-radius: 100%;
			background-size: cover; background-repeat: no-repeat; 
			background-position: center; margin-right: 10px;
			background-color: #333;
		}
		.author-details {
			display: flex; flex-direction: column;
			.author-job {
				font-size: 12px; line-height: 1em;
			}
			.author-name {
				font-size: 14px; color: #FED417; font-weight: 600;
			}
		}
	}
}

.job-details {
	float: left; width: 100%; margin-top: 80px;
	.headline {

	}
	section {
		padding-top: 40px;
	}
	h2 {
		width: 100%; color: #000;
	}
	.blog-image {
		float: left; width: 100%; padding-top: 25%;
		margin-top: 20px; background-size: cover; margin-bottom: 40px;
		background-repeat: no-repeat; background-position: center;

	}
	.intro-text {}
	.job-content {
		max-width: 1024px; display: flex; margin: auto; float: initial; padding: 20px;
		width: 100%; gap: 20px;
		.job-text {
			padding-right: 30px; width: 100%;
			h3 {
				float: left; width: 100%;
			}
			ul {
				padding-left: 30px; margin-bottom: 30px; float: left;
				li {
					margin-bottom: 10px; position: relative; width: 100%;
					&::before {
						content: ''; position: absolute; left: -30px; top: 0px;
						background: url('../images/ico-check-black.svg') left top no-repeat;
						width: 30px; height: 30px;
					}
				}
			}
		}
		.job-expert {
			flex: 0 0 250px;
		}
		&.white {
			.job-text {
				background: #FFF; color: #000;
	    		padding: 20px; border-radius: 9px; border: 2px solid #bcc7d2;
	    		h3 { color: #000; }
	    		ul {
				li {
					&::before {
						background: url('../images/ico-check-black.svg') left top no-repeat;
					}
				}
			}
	    	}
		}
	}
	.image-grid {
		display: grid; grid-template-columns: repeat(2, 1fr);
    	grid-gap: 20px;
    	.image {
    		padding-top: 50%; width: 100%; border-radius: 9px;
    		background-size: cover; background-repeat: no-repeat;
    		background-position: center;
    	}
	}
	.process {
		padding: 0; background: #121D30;
		float: left; width: 100%;
		.content {
			display: flex; grid-gap: 40px; flex-direction: row;
			.process-row {
				float: left; width: 50%; transform: translateY(50px);
				&.first {
					transform: translateY(-20px);
				}
				.process-item {
					width: 100%; background: #1B2638; border-radius: 9px; 
					margin-top: 80px; float: left; margin-bottom: 20px; 
					padding: 20px;
					.square {
						width: 80px; height: 80px; border-radius: 9px;
						background: #FED417; float: left; margin-top: -60px;
						margin-bottom: 20px;
						&.ico-assertive {
							background: #FED417 url('../images/ico-assertive.svg') center center no-repeat;
						}
						&.ico-movement {
							background: #FED417 url('../images/ico-movement.svg') center center no-repeat;
						}
						&.ico-ambition {
							background: #FED417 url('../images/ico-ambition.svg') center center no-repeat;
						}
						&.ico-enjoy {
							background: #FED417 url('../images/ico-enjoy.svg') center center no-repeat;
						}
						&.ico-chances {
							background: #FED417 url('../images/ico-chances.svg') center center no-repeat;
						}
					}
					h4 {
						float: left; width: 100%; margin-bottom: 5px;
					}
				}
			}
		}
	}
	.howto-wrapper {
		padding-top: 100px;
	}
	.how-to {
		float: left; width: 100; margin-top: 20px;
		.howto-item {
			float: left; width: 100%; margin-bottom: 25px;
			.index {
				    width: 80px; margin-right: 20px; float: left; background: #ffffff1c; 
				    border: 10px solid #12192c; border-radius: 100%; 
				    font-family: "Geogrotesque"; font-weight: 700; font-size: 30px; 
				    height: 80px; display: flex; justify-content: center; align-items: center;
			}
			.howto-content {
				float: left; width: calc(100% - 100px);
				h4 {
					margin-bottom: 0px;
				}
			}
		}
	}
	.content-form {
		.column-form {
			background: #111928; border: none; float: left;
		}
	}
}

.contact .headline {
	background: linear-gradient(90deg, #08172C 0%, #0B2F50 100%);
	padding-bottom: 0px;
	.column-form {
		transform: translateY(30px);
	}
}
.team .headline,
.jobs .headline,
.aboutus .headline {
	background: linear-gradient(90deg, #08172C 0%, #0B2F50 100%);
}

.section-werken {
	.job-details {
		margin-top: 0px;
	}
	.contact .headline {
		background: #FED417;
	}
	
	.headline {
		h1 {
			span {
				padding: 0 10px;
			}
		}
		.description, h2, .contact-item h3, .btn-watch {
			color: #000;
		}
	}
	.contact h3 {
		color: #FFF;
	}
}

.not-found {
	display: flex; align-items: center; height: 100vh;
	width: 100%;
	.content, {
		display: flex; align-items: center;
	}
}

.page-positions {
	position: relative;
	&:before {
		content: ''; width: 948px; height: 711px;
		background: url('../images/sattelite.png') center center no-repeat;
		background-size: 948px 711px; position: absolute; right: -150px;
    	top: -150px;
	}
	.content {
		z-index: 5000;
	}
}

.home-werken {

}
.rocket {
	position: absolute;
    bottom: 0px;
    max-width: 300px;
    z-index: 50000;
    right: 30px;
}
.section-werken section.headline h1 {
	transform: rotate(-2deg); color: #FFF;
	span, .home-werken section.headline .half-text h1 span:nth-child(2) {
		color: #FFF !important;
	}
}
.section-werken .aboutus .headline h1 span {
	color: #FED417;
}

@media screen and (max-width: 960px) {
	.job-filters .profile {
		display: none;
	}
	.hideonmobile {
		display: none;
	}
	.langmenu.hideonmobile {
		display: none;
	}
	.langmenu-mobile {
		display: block;
	}
	.jobs-filters-wrapper .jobs-filters .filter ul {
		display: inherit;
		li {
			white-space: initial; margin: 5px;
		}
	}
	.section-werken h2 {
	    font-size: 34px;
	}
	.rocket {
		right: 20px; max-width: 180px;
	}
	.page-positions:before {
		transform: scale(.5);
	}
	.section-werken h2 {
		line-height: 1.4em;
	}
	.intro .menu-items a {
		width: 100%;
	}
	.column.half {
		width: 100%; float: left; padding-right: 0px;
	}
	.intro .intro-wrapper {
		padding: 20px;
	}
	.intro .intro-wrapper img {
		max-width: 100%; height: auto;
	}
	.column.newspaper {
		column-count: 1;
	}
	body {
		font-size: 12px;
	}
	section {
		padding: 40px 0;
	}
	h1 {
		font-size: 32px;
	}
	h2 {
	    font-size: 28px; margin-bottom: 0px;;
	}
	h3 {
	    font-size: 20px;
	}
	.section-werken {
		.menu-wrapper {
			background: #FED417;
		}
	}
	.job-details .process .content .process-row {
		transform: translateY(0px);
	}
	.niches-menu, .column-2n, .blog-section .blog-items, footer .footer-items {
		flex-direction: column; display: flex; grid-gap: 0px; float: left;
    	width: 100%;
	}
	.column-2n.switch {
		flex-direction: column-reverse;
	}
	.contact {
		.column-2n {
			flex-direction: column;
		}
	}
	.job-section, .job-section .job-jobs, .team-section, .intro .menu-items, .job-details .image-grid {
		flex-direction: column; display: flex;
	}
	footer .footer-items .footer-privacy, footer .footer-items .footer-links, footer .footer-items .footer-contact {
		flex: 0 0 0px !important; margin-bottom: 20px;;
	}
	.job-details .process .content {
		flex-direction: column; display: flex; grid-gap: 20px;
	}
	.intro-text {
		font-size: 16px;
	}
	.column-2n.with-background, .column-form {
		padding: 20px;
	}
	.btn-blog, .column-map {
		margin-bottom: 20px;
	}
	section.headline .content {
		flex-direction: column-reverse;
		.half-text, .half-image {
			width: 100%; padding: 0px; padding-top: 20px;
		}
		.half-image {
			padding-top: 50%; transform: translateY(0px);
		}
	}
	section.headline {
		padding-top: 80px;
	}
	section.with-background, .column-box {
		padding: 20px;
	}
	.blog-section .blog-items, .niches-menu {
		margin-top: 20px; float: left;
	}
	.column {
    	float: left; width: 100%; margin-top: 20px;
	}
	.job-details h2, .intro-text, .job-details .process .content .process-row, .job-details .how-to .howto-item .howto-content {
		width: 100%;
	}
	.job-details .job-content {
		padding: 20px; padding-top: 40px;
	}
	.job-details .process .content .process-row.first {
		transform: translateY(0px);
	}
	.job-details .how-to .howto-item .index {
		display: none;
	}
	section.headline .content .half-text {
		padding-bottom: 20px;
	}
	.swiper-button-next, .swiper-button-prev {
		top: auto; bottom: 0px;
	}
}