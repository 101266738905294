/*########################################
/#####RESET ELEMENTS
########################################*/
* {
  margin: 0; padding: 0; box-sizing: border-box; 
}
ul {
  list-style: none; list-style-type: none;
}
ul li {
  float: left;
}
img, fieldset {
  border: none;
}
h1, h2, h3, h4, h5, h6 {
  font-weight: normal; margin-bottom: 15px;
}
@font-face {
    font-family: 'Geogrotesque';
    src: url('font-files/geogrotesquecyr-extralight-webfont.woff2') format('woff2'),
         url('font-files/geogrotesquecyr-extralight-webfont.woff') format('woff');
    font-weight: normal; font-weight: 400;
}
@font-face {
    font-family: 'Geogrotesque';
    src: url('font-files/geogrotesquecyr-semibold-webfont.woff2') format('woff2'),
         url('font-files/geogrotesquecyr-semibold-webfont.woff') format('woff');
    font-weight: normal; font-weight: 700;
}
@font-face {
    font-family: 'Geogrotesque';
    src: url('font-files/geogrotesquecyr-bold-webfont.woff2') format('woff2'),
         url('font-files/geogrotesquecyr-bold-webfont.woff') format('woff');
    font-weight: normal; font-weight: 800;
}
@font-face {
    font-family: 'Manrope';
    src: url('font-files/manrope-light-webfont.woff2') format('woff2'),
         url('font-files/manrope-light-webfont.woff') format('woff');
    font-weight: normal; font-weight: 300;
}
@font-face {
    font-family: 'Manrope';
    src: url('font-files/manrope-semibold-webfont.woff2') format('woff2'),
         url('font-files/manrope-semibold-webfont.woff') format('woff');
    font-weight: normal; font-weight: 700;
}
@font-face {
    font-family: 'Manrope';
    src: url('font-files/manrope-extralight-webfont.woff2') format('woff2'),
         url('font-files/manrope-extralight-webfont.woff') format('woff');
    font-weight: 200;
    font-style: normal;
}
/*########################################
/#####MAIN ELEMENTS
########################################*/
body {
  font-family: 'Geogrotesque'; color: #e8ecf2; font-size: 16px; font-weight: 200;
  background: radial-gradient(50% 50% at 50% 50%, #08182E 0%, #070D1C 100%);
  background-repeat: no-repeat; min-height: 100vh; line-height: 1.4em;
}
.content * {
  white-space: pre-line
}
a {
  color: #FFF;
}
p {
  float: left; margin-bottom: 15px;
}
h1, h2, h3, h4, h5, h6 {
  font-family: 'Geogrotesque'; font-weight: 700; color: #FFF;
  line-height: 1.1em;
  &.no-margin {
    margin-bottom: 0px;
  }
}
h1 {
  font-size: 40px; color: #08182E; background-color: #FED417;
  float: left; padding: 0px; font-weight: 700; line-height: 1.5em;
  span:nth-child(1) {
    color: #08182E; background-color: #FED417;
    margin-right: 0px; line-height: 1.1em; float: left;
  }
  span:nth-child(2) {
    color: #FFF; background-color: #08182E; float: left;
    margin-right: 0px; font-weight: 400; line-height: 1.1em;
  }
  span {
    padding: 0 10px;
  }
}
.home {
  h1 {
    span:nth-child(1) {
      float: left;
    }
    span:nth-child(2) {
      float: left;
    }
  }
}
h2 {
  font-size: 38px; 
  span {
    padding: 0 5px;
  }
  span:nth-child(1) {
    color: #08182E; background-color: #FED417;
    margin-right: 0px;
  }
  span:nth-child(2) {
    color: #FFF; background-color: #08182E;
    margin-right: 0px;
  }
}
.error {
  float: left; width: 100%; margin-bottom: 15px; font-size: 13px; 
  font-style: italic; color: #ffd458;
}

h2 span:empty, h1 span:empty {
    display:none;
}

.section-werken {
  h1 {
    font-size: 40px; color: #08182E; background-color: #FED417;
    float: left; font-weight: 600; line-height: 1.5em;
    span:nth-child(1) {
      color: #000; background-color: #08182E;
      margin-right: 0px;
    }
    span:nth-child(2) {
      color: #08182E; background-color: #FED417;
      margin-right: 0px;
    }
  }
  h2 {
    font-size: 38px; 
    span:nth-child(1) {
      color: #08182E; background-color: #FFF;
      margin-right: 0px;
    }
    span:nth-child(2) {
      color: #08182E; background-color: #FED417;
      margin-right: 0px;
    }
  }
  .carousel, .headline {
    h1 {
      color: #FFF; background-color: #08182E;
      margin-right: 0px;
      // span:nth-child(1) {
      //   color: #08182E; background-color: #FED417;
      //   padding: 0px; margin-right: 0px;
      // }
      span:nth-child(2) {
        color: #FFF; background-color: #08182E;
        margin-right: 0px;
      }
    }
  }
}
.section-werken .aboutus .headline h1 span {
  color: #FFF;
}

h3 {
  font-size: 24px;
}
h4 {
  font-family: 'Geogrotesque'; font-size: 18px; color: #d8dfed;
  line-height: 1.3em;
}
h5 {
  font-family: 'Geogrotesque'; font-size: 14px; color: #d8dfed;
  line-height: 1.3em; font-weight: 400;
}
hr {
    border: none; width: 100%; height: 1px; background: #4c5156;
}
label {
  font-family: 'Geogrotesque'; font-size: 14px; font-weight: 700;
  float: left; width: 100%; margin-bottom: 2px;
}
input[type=password], input[type=text], input[type=number], textarea {
  background-color: #0D1D32; font-family: 'Geogrotesque';
  font-size: 14px; padding: 10px 15px; margin-bottom: 10px;
  border: 1px solid rgba(255, 255, 255, 0.2); color: #FFF;
  border-radius: 9px; width: 100%; resize: none;
}
.contact-form {
  input[type=text], input[type=number], textarea {
    color: #FED417;
  }
}
.clear {
  float: left; width: 100%; clear: both;
}
.description {
  float: left; width: 100%;
}
.intro-text {
  float: left; width: 50%; font-size: 18px; clear: both; 
  line-height: 1.3em; color: #FFF;
  white-space: pre-line;
}
.center {
  .intro-text {
    float: inherit;display: flex;
    margin: auto; margin-bottom: 25px;
  }
}
.content {
  width: 100%; max-width: 1024px; margin: auto;
  padding: 0 20px; position: relative;
}
.btn-main {
  font-family: "Geogrotesque"; font-style: normal; font-weight: 600; 
  font-size: 16px; text-align: center; color: #000000; float: left;
  background-color: #FED417; text-decoration: none; 
  padding: 9px 20px; border-radius: 4px; overflow: hidden;
  transition: 0.25s ease background; position: relative;
  border: 2px solid #FED417; cursor: pointer;
  
  &:before {
    content:""; display: block; position: absolute; top:50%; 
    left:50%; border-radius:50%; width:0%; padding-top:0%; 
    transform: translate(-50%,-50%); background: #FFF; 
    transition: 0.5s ease padding-top, 0.5s ease width;
    opacity: 0.4;
  }
  &:hover {
    &:before {
      width:150%; padding-top:150%;
    }
  }
  span {
    position: relative; z-index: 500;
  }
  &.small {
    padding: 5px 10px; font-size: 13px; white-space: nowrap;
  }
}
.tag {
  background-color: #FFF; float: left; font-weight: 700;
  font-family: 'Geogrotesque'; color: #0b0f19; padding: 0 5px;
  border-radius: 3px; margin-right: 5px;
  &.yellow {
    background-color: #FED417;
  }
  &.transparent {
    color: #FFF; background-color: transparent; width: 100%;
    margin-top: 5px; padding: 0px;
  }
}
.btn-main-line {
  font-family: "Geogrotesque"; font-style: normal; font-weight: 600; 
  font-size: 16px; text-align: center; color: #000000; float: left;
  border: 2px solid #FED417; text-decoration: none; color: #FED417;
  padding: 9px 20px; border-radius: 4px; overflow: hidden;
  transition: 0.25s ease background; position: relative;
  
  &:before {
    content:""; display: block; position: absolute; top:50%; 
    left:50%; border-radius:50%; width:0%; padding-top:0%; 
    transform: translate(-50%,-50%); background: #FED417; 
    transition: 0.5s ease padding-top, 0.5s ease width;
    opacity: 0.7;
  }
  &:hover {
    &:before {
      width:150%; padding-top:150%;
    }
    span {
      color: #000000;
    }
  }
  span {
    position: relative; z-index: 500;
  }
}
.btn-watch {
  color: #FED417; font-weight: 600;
  text-decoration: none; display: flex;
  clear: both; display: flex;
  align-items: center;
  * {  transition: 0.25s ease all; }
  svg {
    margin-left: 5px;
  }
  &:hover {
    color: #FFF;
    svg {
      margin-left: 10px;
    }
  }
}

.Dropdown-root {
  float: left; margin-bottom: 15px; width: 100%;
  .Dropdown-control {
    background-color: #172135; border-radius: 9px;
    height: 40px; border: 1px solid #414959; padding: 10px 15px;
    color: #FFF;
  }
  .Dropdown-menu {
    background-color: #172135; border-radius: 9px; margin-top: 5px;
    border: 1px solid #414959; padding: 10px 15px;
    .Dropdown-option {
      color: #FFF; padding: 5px 0;
      &:hover {
        background-color: transparent; color: #FED417;
      }
      &.is-selected {
        background-color: transparent; color: #FED417; font-weight: 800;
      }
    }
  }
  .Dropdown-arrow {
    top: 17px; right: 15px;
  }
}

.popup-inner .Dropdown-root .Dropdown-control .Dropdown-placeholder {
  color: #FED417;
}

.swiper {
  width: 100%; height: 100%; max-width: 480px; 
  position: inherit;
}
.swiper-button-prev {
  position: absolute; right: 40px; top: 0px; background: #1E2738 url('../images/ico-prev.svg') center center no-repeat; 
  width: 30px; height: 30px; border-radius: 100%; z-index: 5000; 
  cursor: pointer;
  &:hover {
    background-color: #FED417;
  }
} 
.swiper-button-next {
  position: absolute; right: 0px; top: 0px; background: #1E2738 url('../images/ico-next.svg') center center no-repeat; 
  width: 30px; height: 30px; border-radius: 100%; z-index: 5000; cursor: pointer;
  &:hover {
    background-color: #FED417;
  }
}
.swiper-button-disabled {
  display: none;
}
.swiper-wrapper {
  position: inherit; padding-bottom: 30px;
}
.swiper-slide {
  display: flex; flex-direction: column; justify-content: center;
}

.swiper-slide img {
  display: block; width: 100%; height: 100%; object-fit: cover;
}
.swiper-pagination {
  display: flex; z-index: 5000; position: absolute; bottom: 0px;
  grid-gap: 10px;
  .swiper-pagination-bullet {
    width: 5px; height: 5px; border-radius: 100%; background: #ffffff4f;
    transition: 0.25s ease all;
    &.swiper-pagination-bullet-active {
      width: 15px; background: #FFF; border-radius: 3px;
    }
  }
}
.react-select-menu * {
  color: #000 !important; font-weight: 800;
  &:before {
    color: #000 !important;
  }
}
.react-select-container {
  margin-bottom: 15px; float: left; width: 100%;
  .react-select__control {
    background: #0c1d32; border-color: #ffffff6b;
  }
  .react-select__menu {
    background-color: #0c1d32;
  }
  .react-select-menu .react-select__menu-list, .react-select__option {
    color: #FFF; background-color: #0c1d32;
    &:hover {
      color: #FED417;
    }
  }
  .react-select__multi-value__remove {
    height: 18px; margin-right: 5px; margin-top: 5px; 
    padding-left: 1px; padding-right: 1px; margin-left: 5px; 
    border: 1px solid #ffffff94;
    &:hover {
      background-color: #ffe1ab00; color: #FED417;
    }
  }
  .react-select__multi-value {
    background-color: transparent; color: #FFF; border: 1px solid #ffffff26; border-radius: 4px; padding: 0 5px;
    .react-select__multi-value__label {
      color: #FED417;
    }
  }
}

// .react-select__control {
//   background: #0c1d32; border-color: #ffffff30;
//   color: #FFF;

// }
// .react-select-container {
//    margin-bottom: 15px; color: #FFF;
// }
// .react-select-menu .react-select__menu-list, .react-select__option {
//   color: #FFF; background-color: #0c1d32;
// }
// .react-select__single-value {
//   color: #FFF; background-color: transparent;
// }
